import React from 'react';
//components
import Layout from '../components/Layout';
import MediaDesktop from '../components/Desktop/Media';
//styles
import './style.css';

class Media extends React.Component {
  render() {
    return (
      <Layout
        mobile={<MediaDesktop />}
        desktop={<MediaDesktop />} />
    );
  }
}

export default Media;