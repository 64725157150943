import styled from 'styled-components';

export const Container = styled.div` 
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  height: 27.31vw;
`;

export const Heading = styled.div`
  font-family: SilverSouthSerif;
  font-size: 3.500vw;
  color: #2C2C2C;
  letter-spacing: 0.06250vw;
  margin-bottom: 3.500vw;
`;

export const SubHeading = styled.div`
  font-family: sofia-pro, sans-serif;
  font-weight: 700;
  font-size: 1.125vw;
  color: #2C2C2C;
  letter-spacing: 0.06250vw;
  text-transform: uppercase;
`;

export const SubHeading2 = styled.div`
  font-family: calluna, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.000vw;
  color: #2C2C2C;
  letter-spacing: 0;
  margin-top: 2.000vw;
  margin-bottom: 2.000vw;
`;

export const Button = styled.div`
  cursor: pointer;
  margin: 0 auto;
  margin-top: 1.438vw;
  z-index: 1;
  position: relative;
  background-color: #FE95A2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17.56vw;
  height: 4.000vw;
  > div {
    font-family: sofia-pro, sans-serif;
  font-weight: 700;
    font-size: 1.125vw;
    color: #FFFFFF;
    letter-spacing: 0;
    text-align: center;
    line-height: 1.250vw;
  }
  > img {
    max-height: 1.000vw;
    width: 1.375vw;
    margin-left: 0.5625vw;
  }
`;