import React from 'react';
import {
  Container, Heading, PageURL, HeadingContainer,
} from './styled';
import Body from './Body';
import CTA from './CTA';

function Media() {
  return (
    <Container>
      <HeadingContainer>
        <Heading>Se Beauftil So Confident</Heading>
        <PageURL>SO BRIDELY</PageURL>
      </HeadingContainer>
      <Body />
      <CTA />
    </Container>
  );
}

export default Media;
