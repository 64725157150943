import styled from 'styled-components';

export const Container = styled.div`
  background: #F3F0EB;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Heading = styled.div`
  font-family: SilverSouthScript;
  font-size: 4.375vw;
  color: #464855;
  letter-spacing: 0;
  text-align: center;
`;

export const PageURL = styled.div`
  font-family: SilverSouthSerif;
  font-size: 6.938vw;
  color: #464855;
  letter-spacing: 0;
`;

export const HeadingContainer = styled.div`
  height: 23.06vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;