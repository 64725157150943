import React from 'react';
import {
  Container, Heading, SubHeading, SubHeading2, Button,
} from './styled';
import DownloadIcon from './Download.svg';

function Body() {
  return (
    <Container>
      <Heading>MEDIA KIT</Heading>
      <SubHeading>Contact us directly for media/pr questions. </SubHeading>
      <SubHeading2>The Media Kit includes our logo & media/pr information.</SubHeading2>
      <Button>
        <div>Download</div>
        <img src={DownloadIcon} />
      </Button>
    </Container>
  );
}

export default Body;
